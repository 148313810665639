.required::after {
  content: " *";
  color: red;
}

.card {
  border-radius: 20px !important;
  background-color: #4a6572 !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  color: #ffffff !important;
}

.card.card-login {
  text-align: center;
  padding: 10px;
  flex-grow: 1;
  border-radius: 50px !important;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 10px 50px 20px !important;
}

.card-carto {
  text-align: center;
  flex-grow: 1;
  border-radius: 0px !important;
  flex-direction: column;
  box-shadow: none;
}

.card-body {
  padding: 0.5rem 0.5rem;
}

.card-header {
  border-radius: 15px 15px 0px 0px !important;
  background-color: #3f565f !important;
}

.card-header-login {
  border-radius: 40px 40px 20px 20px !important;
  background-color: #4a6572 !important;
}

.card-header-carto {
  border-radius: 0px !important;
  background-color: #4a6572 !important;
}

.card-footer {
  border-radius: 0px 0px 20px 20px !important;
  background-color: #4a6572 !important;
}

.card-footer-login {
  border-radius: 40px 40px 50px 50px !important;
  background-color: #4a6572 !important;
}

#nav-link-doc {
  display: flex;
  justify-content: center;
  border-radius: 30px !important;
  transition: background-color 0.2s;
}

#nav-link-doc:hover {
  background-color: #4a6572 !important;
}

#nav-link-contact {
  display: flex;
  justify-content: center;
  border-radius: 30px !important;
  transition: background-color 0.2s;
}

#nav-link-contact:hover {
  background-color: #4a6572 !important;
}

#nav-link-exploitation {
  text-decoration: none;
}

.container-fluid .dropdown {
  transition: background-color 0.2s;
  border-radius: 30px !important;
}

.container-fluid .dropdown-topbar {
  display: flex;
  align-items: center;
}

.container-fluid .dropdown-topbar:hover {
  background-color: #4a6572 !important;
}

.btn:hover {
  color: inherit;
}

.btn-menu:hover {
  color: black;
}

.formtext-input-file {
  color: inherit !important;
}

.select-input {
  background-color: #4a6572;
  color: inherit;
}

.dropdown-menu {
  background-color: #4a6572 !important;
}
.dropdown-item {
  background-color: #4a6572 !important;
  color: #fff;
}

.dropdown-item.disabled {
  background-color: #44575f !important;
  color: #f9aa33 !important;
}

.dropdown-item .a {
  background-color: #4a6572 !important;
  color: inherit !important;
}
.dropdown-item:hover {
  background-color: #455c66 !important;
}
.dropdown-header {
  background-color: #4a6572 !important;
}
.dropdown-divider {
  background-color: #4a6572 !important;
}

.form-control {
  background-color: #526b79 !important;
  color: #fff !important;
}

.form-control:disabled {
  background-color: #3b505a !important;
  opacity: 0.5;
}

.form-select:disabled {
  background-color: #3b505a !important;
  opacity: 0.5;
}

.form-control::placeholder {
  background-color: #526b79 !important;
  color: rgba(255, 255, 255, 0.4) !important;
}



#panel1a-header {
  background-color: #4a6572;
  color: #fff;
  align-items: center;
  justify-content: center;
}

#panel1a-content {
  background-color: #4a6572;
}

.MuiAccordionSummary-content.MuiAccordionSummary-contentGutters.css-o4b71y-MuiAccordionSummary-content {
  justify-content: center;
}

.leaflet-container {
  width: 100%;
  height: 100vh;
  z-index: 0;
}

tr.fichier:hover {
  background-color: rgb(65, 96, 116);
}

tr.fichier-menu {
  background-color: rgb(66, 89, 105);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

table tr td, table tr th{
  background-color: inherit !important;
  color: #ffffff !important;
}

.table-container {
  margin: 10px;
  padding: 0;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
}

tr.expandable > td {
  box-shadow: inset 0 3px 6px -3px rgba(0, 0, 0, 0.2);
  padding: 0;
}

tr.expandable > td > .inner {
  margin: 5px;
  overflow: hidden;
}

.fichier-action:hover {
  background-color: rgb(65, 96, 116);
}

.input-group {
  position: relative;
}

.input-group label {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(10px, 10px);
  transform-origin: left;
  transition: transform 0.25s;
}

.input-group #name {
  padding: 10px;
  border: none;
  border-radius: 4px;
  font: inherit;
  color: #fff;
  background-color: transparent;
  outline: 3px solid #fff;
}

.input-group:focus-within label {
  transform: translate(0, -20px) scale(0.8);
  color: #fff;
}

.input-group:focus-within #name {
  outline-color: #fff;
}

.table-affectation thead tr {
  background-color: #324e5a;
  color: #fff;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: rgb(0 0 0 / 13%);
  color: #fff;
}

.modal-header-form {
  background-color: #3f565f !important;
  color: #fff;
}

.modal-body-form {
  background-color: #526b79 !important;
  color: #fff;
}

.modal-footer-form {
  background-color: #526b79 !important;
  color: #fff;
}

.select-single {
  background-color: #526b79 !important;
}